<template>
    <nav
        v-if="findLayoutClass()==='vertical'"
        id="layout-navbar"
        class="layout-navbar navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme py-0 rounded-0 shadow-sm"
        :class="['lnb-' + findLayoutClass()]"
    >

        <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
            <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i class="ti ti-menu-2 ti-sm"></i>
            </a>
        </div>

        <NavbarRight />
    </nav>
    <nav
        v-else-if="findLayoutClass()==='horizontal'"
        id="layout-navbar"
        class="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme py-0 rounded-0"
    >
        <div class="container-fluid">

            <div class="navbar-brand app-brand demo d-none d-lg-flex py-0 me-4">
                <router-link v-if="!guestEmbedded()" :to="{ name:'home' }" class="app-brand-link">
                    <span class="app-brand-logo">
                        <img :src="$gpApp.logo()" alt="Logo">
                    </span>
                    <span
                        class="app-brand-text demo menu-text fw-bold"
                        :style="($gpApp.fontColor()?'color:'+$gpApp.fontColor():'')"
                    >
                        {{ $gpApp.shortname() }}
                    </span>
                </router-link>
                <a v-else :href="goRootDomain()" class="app-brand-link">
                    <span class="app-brand-logo">
                        <img :src="$gpApp.logo()" alt="Logo">
                    </span>
                    <span
                        class="app-brand-text demo menu-text fw-bold"
                        :style="($gpApp.fontColor()?'color:'+$gpApp.fontColor():'')"
                    >
                        {{ $gpApp.shortname() }}
                    </span>
                </a>

                <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-lg-none">
                    <i class="ti ti-x ti-sm align-middle"></i>
                </a>
            </div>

            <div class="layout-menu-toggle navbar-nav align-items-lg-center me-3 me-lg-0 d-lg-none">
                <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                    <i class="ti ti-menu-2 ti-sm"></i>
                </a>
            </div>

            <NavbarRight />
        </div>
    </nav>
</template>

<script>
import {layoutHelper} from "../../utils/helpers/layoutHelper.js";
import NavbarRight from "../navbar/NavbarRight.vue";
import {elementHelper} from "../../utils/helpers/elementHelper.js";
import {appHelper} from "../../utils/helpers/appHelper.js";
export default {
    name: "Navbar",
    components: {
        NavbarRight
    },
    mounted() {
        window.addEventListener("resize", this.layoutMenuToggle);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.layoutMenuToggle);
    },
    methods: {
        layoutMenuToggle() {
            const layoutMenuToggle = this.$el.querySelector('.layout-menu-toggle.navbar-nav');

            if(layoutMenuToggle) {
                if(layoutMenuToggle.classList.contains('d-none')) {
                    layoutMenuToggle.classList.remove('d-none')
                }
            }

            if(layoutMenuToggle && elementHelper.getWindowWidth()<window.Helpers.LAYOUT_BREAKPOINT) {
                const menuLink = layoutMenuToggle.querySelector('.layout-menu-toggle.navbar-nav .nav-link');
                if(!menuLink.classList.contains('on-event-click')) {
                    menuLink.classList.add('on-event-click');
                    menuLink.addEventListener('click', event => {
                        if(layoutHelper.getLayoutOrientation()==='horizontal') {
                            if(window.Helpers.mainMenu._horizontal) {
                                window.Helpers.mainMenu.switchMenu('vertical');
                                this.$parent.$refs.horizontalMainMenu.layoutMenuToggle();
                            }
                        }
                        window.Helpers.toggleCollapsed();
                    });
                }
            } else if(layoutHelper.getLayoutOrientation()==='horizontal' && elementHelper.getWindowWidth()>=window.Helpers.LAYOUT_BREAKPOINT) {
                if(!window.Helpers.mainMenu._horizontal) {
                    window.Helpers.mainMenu.switchMenu('horizontal');
                }
            }
        },
        findLayout() {
            return layoutHelper.findLayout(this.$route.name);
        },
        findLayoutClass() {
            const layout = this.findLayout();
            return (layout==='vertical-blank' ? 'vertical' : (layout==='horizontal-blank' ? 'horizontal' : layout));
        },
        goRootDomain() {
            return appHelper.url.root();
        },
        guestEmbedded() {
            return appHelper.environment.guestEmbedded;
        }
    }
}
</script>

<style scoped>

</style>
