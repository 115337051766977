import {menuHelper} from "./menuHelper.js";

export const layoutHelper = {
    routeNameMyAccount:['myAccount'],
    findLayout(key = null) {
        let orientation;
        if (key && layoutHelper.routeNameMyAccount.includes(key)) {
            orientation = menuHelper.type(layoutHelper.options.layouts[key]);
        } else if (key in layoutHelper.options.layouts) {
            orientation = layoutHelper.options.layouts[key];
        } else {
            orientation = menuHelper.type('blank');
        }

        // Only switch menu if orientation is determined
        if (orientation) {
            layoutHelper.switchMenu(orientation);
        }

        return orientation;
    },
    findDirectRoute(key) {
        if (key in layoutHelper.options.directs) {
            return layoutHelper.options.directs[key];
        } else {
            return null;
        }
    },
    options : {
        layouts : {
            home : 'horizontal',
            myAccount : 'horizontal',
            errorPage : 'blank',
            errorPageImported : 'blank',
            login : 'blank',
            forgotPassword : 'blank',
            register : 'blank',
            verifyEmail : 'blank',
            confirmEmail : 'blank',
            twoStepVerificationEmail : 'blank',
            twoStepVerification : 'blank',
            resetPassword : 'blank'
        },
        directs : {
            // user: 'userProfile',
            // userSetting: 'settingAccount',
        }
    },
    switchMenu(orientation) {
        const html = document.querySelector('html');
        const layout = html.getAttribute('data-layout');
        const layoutWrapper = html.querySelector('.layout-wrapper-app');
        const menuOrientation = (orientation==='vertical-blank' ? 'vertical' : (orientation==='horizontal-blank' ? 'horizontal' : orientation));

        if(layout!==menuOrientation) {
            html.setAttribute('data-template', menuOrientation + '-menu-template');
            html.setAttribute('data-layout', menuOrientation);

            if(html.classList.length) {
                while (html.classList.length > 0) {
                    html.classList.remove(html.classList.item(0));
                }
            }

            if(layoutWrapper) {
                if(layoutWrapper.classList.length) {
                    while (layoutWrapper.classList.length > 0) {
                        layoutWrapper.classList.remove(layoutWrapper.classList.item(0));
                    }
                }
            }

            if(menuOrientation==='horizontal') {
                window.Helpers.LAYOUT_BREAKPOINT = 992;
                html.classList.add('light-style', 'layout-menu-fixed', 'layout-compact', 'layout-footer-fixed');
                if(layoutWrapper) {
                    layoutWrapper.classList.add('layout-wrapper-app', 'layout-wrapper', 'layout-navbar-full', 'layout-horizontal', 'layout-without-menu');
                }
            } else if(menuOrientation==='vertical') {
                window.Helpers.LAYOUT_BREAKPOINT = 1200;
                html.classList.add('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-footer-fixed');
                if(layoutWrapper) {
                    layoutWrapper.classList.add('layout-wrapper-app', 'layout-wrapper', 'layout-content-navbar');
                }
            } else {
                window.Helpers.LAYOUT_BREAKPOINT = 1200;
                html.classList.add('light-style', 'layout-wide');
                if(layoutWrapper) {
                    layoutWrapper.classList.add('layout-wrapper-app');
                }
            }
        }
    },
    getLayoutOrientation() {
        return document.querySelector('html').getAttribute('data-layout');
    }
}
