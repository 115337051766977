import {appHelper} from "../utils/helpers/appHelper.js";

export default function guest({ next, store }) {
    const storageItem = window.localStorage.getItem("guest");
    if (storageItem === "isNotGuest" && !store.getters["auth/authUser"]) {
        store.dispatch("auth/getAuthUser").then(() => {
            if (store.getters["auth/authUser"]) {
                if(appHelper.environment.guestEmbedded) {
                    window.location.href = appHelper.url.root() + appHelper.path.home;
                } else {
                    next({ name: 'home' });
                }
            } else {
                store.dispatch("auth/setGuest", { value: "isGuest" });
                next();
            }
        });
    } else {
        next();
    }
}
