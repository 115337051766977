import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

export const AmsAppChannels = [
    {
        path: appHelper.path.amsApp + '/distribution/channel',
        name: 'amsAppChannel',
        component: () => import('../views/channel/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/distribution/channel/sub-channel',
        name: 'amsAppSubChannel',
        component: () => import('../views/sub-channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            title: 'Sub Channels',
            column : {
                label :'Channel',
                property :'channel_parent',
                modelKey :'parent_name',
                sourceFilter :'/amsapp/distribution/channel',
            }
        }
    },
    {
        path: appHelper.path.amsApp + '/distribution/chain',
        name: 'amsAppChain',
        component: () => import('../views/channel/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/distribution/chain/sub-chain',
        name: 'amsAppSubChain',
        component: () => import('../views/sub-channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            title: 'Sub Chains',
            column : {
                label :'Chain',
                property :'chain_parent',
                modelKey :'parent_name',
                sourceFilter :'/amsapp/distribution/chain',
            }
        }
    },
    {
        path: appHelper.path.amsApp + '/distribution/store',
        name: 'amsAppStore',
        component: () => import('../views/store/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/product/category',
        name: 'amsAppCategory',
        component: () => import('../views/channel/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/product/category/product-type',
        name: 'amsAppProductType',
        component: () => import('../views/channel/index.vue'),
        meta: { middleware: [auth, shortcuts], title: 'Product Types' }
    },
    {
        path: appHelper.path.amsApp + '/product/category/sub-category',
        name: 'amsAppSubCategory',
        component: () => import('../views/sub-channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            title: 'Sub Categories',
            column : {
                label :'Category',
                property :'category_parent',
                modelKey :'parent_name',
                sourceFilter :'/amsapp/product/category',
            }
        }
    },
    {
        path: appHelper.path.amsApp + '/product/brand',
        name: 'amsAppBrand',
        component: () => import('../views/channel/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/product/brand/sub-brand',
        name: 'amsAppSubBrand',
        component: () => import('../views/sub-channel/index.vue'),
        meta: {
            middleware: [auth, shortcuts],
            title: 'Brand Products',
            column : {
                label :'Brand',
                property :'brand_parent',
                modelKey :'parent_name',
                sourceFilter :'/amsapp/product/brand',
            }
        }
    }
];
