import store from '../../stores/store.js';
import {elementHelper} from "./elementHelper.js";
import {appHelper} from "./appHelper.js";
import {layoutHelper} from "./layoutHelper.js";

export const menuHelper = {
    async middleware(route) {
        const shortcuts = store.getters["menu/shortcuts"];
        if (shortcuts.length) {
            let shortcut, updateMenu;
            const newShortcuts = [ ...shortcuts ];
            if(layoutHelper.routeNameMyAccount.includes(route.name) && !menuHelper.menus().length && !menuHelper.current()) {
                shortcut = { ...newShortcuts[0] };
                elementHelper.loader.show('body_loader', window.document.body);
                await store.dispatch("menu/getMenus", shortcut.id).then((response) => {
                    if(response.length) {
                        shortcut.menus = response;
                        newShortcuts[0] = {...shortcut};
                        store.commit("menu/setShortcuts", newShortcuts);
                        store.commit("menu/setCurrent", shortcut);
                        store.commit("menu/setMenus", response);
                    }
                }).finally(()=>{
                    elementHelper.loader.hide('body_loader');
                });
            } else {
                for (const key in shortcuts) {
                    if (shortcuts[key].route_frontend) {
                        if (route.path.indexOf(shortcuts[key].route_frontend) !== -1) {
                            shortcut = { ...shortcuts[key] };
                            updateMenu = true;
                            if (shortcut.hasOwnProperty("menus")) {
                                if (shortcut.menus.length) {
                                    updateMenu = false;
                                }
                            }

                            if(updateMenu) {
                                elementHelper.loader.show('body_loader', window.document.body);
                                await store.dispatch("menu/getMenus", shortcut.id).then((response) => {
                                    if(response.length) {
                                        shortcut.menus = response;
                                        newShortcuts[key] = {...shortcut};
                                        store.commit("menu/setShortcuts", newShortcuts);
                                    }
                                }).finally(()=>{
                                    elementHelper.loader.hide('body_loader');
                                });
                            }

                            updateMenu = false;
                            if (!menuHelper.current()) {
                                updateMenu = true;
                            } else if (menuHelper.current().id !== shortcut.id) {
                                updateMenu = true;
                            }

                            if(updateMenu) {
                                store.commit("menu/setCurrent", shortcut);
                                if (shortcut.hasOwnProperty("menus")) {
                                    if (shortcut.menus.length) {
                                        store.commit("menu/setMenus", shortcut.menus);
                                    }
                                } else {
                                    store.commit("menu/setCurrent", null);
                                }
                            }

                            break;
                        }
                    }
                }
            }
        } else {
            store.commit("menu/setCurrent", null);
            store.commit("menu/setMenus", []);
            store.commit("menu/setPermissions", []);
            store.commit("menu/setParent", null);
            store.commit("menu/setPage", null);
        }
    },
    shortcuts() {
        return store.getters["menu/shortcuts"];
    },
    current() {
        return store.getters["menu/current"];
    },
    type(valueDefault = null) {
        const current = menuHelper.current();
        return current ? current.menu_type.toLowerCase() : valueDefault;
    },
    menus() {
        const current = menuHelper.current();
        if(current) {
            if(current.hasOwnProperty("menus")) {
                return current.menus;
            }
        }
        return [];
    },
    tabs() {
        const parent = store.getters["menu/parent"];
        const items=[];
        if(parent) {
            if(parent.menu_type==='tab') {
                if(parent.hasOwnProperty('menus')) {
                    if (!parent.menus.length) {
                        for (const obj of parent.menus) {
                            items.push({
                                id: obj.id,
                                label: obj.name,
                                iconClass: (obj.icon_class?(obj.icon_type==='tabler'?'ti ti-':'bi bi-') + obj.icon_class:null),
                                routeName: obj.route_frontend,
                                routePath: obj.route_middleware
                            })
                        }
                    }
                }
            }
        }
        return items;
    },
    showVerticalMenuLoader() {
        const innerMenu = document.querySelector('#layout-menu.menu-vertical ul.menu-inner');
        if(innerMenu) {
            elementHelper.loader.show('menu_vertical', innerMenu);
        }
    },
    hideVerticalMenuLoader() {
        if(document.querySelector('#layout-menu.menu-vertical ul.menu-inner')) {
            elementHelper.loader.hide('menu_vertical');
        }
    },
    async initialized() {
        if(!menuHelper.menus().length && menuHelper.current()) {
            if(menuHelper.type('')==='vertical') {
                menuHelper.showVerticalMenuLoader();
            }

            await store.dispatch("menu/getMenus", menuHelper.current().id).then((response)=>{
                const current = {...menuHelper.current()};
                const shortcuts = [...menuHelper.shortcuts()]
                const index = shortcuts.findIndex(item => item.id === current.id);
                let menus=[];

                if((menuHelper.type('')==='vertical' || menuHelper.type('')==='vertical-blank') && response.length) {
                    menus = menuHelper.normalizeVerticalMenus(response);
                } else if((menuHelper.type('')==='horizontal' || menuHelper.type('')==='horizontal-blank') && response.length) {
                    menus = menuHelper.indexingMenus(response);
                } else {
                    menus = response;
                }

                shortcuts[index] = {...current, menus:menus, hasInit:true};
                store.commit("menu/setShortcuts", shortcuts);
                store.commit("menu/setCurrent", shortcuts[index]);
                store.commit("menu/setMenus", menuHelper.markRoute(menus));

                if(menuHelper.type('')==='vertical') {
                    menuHelper.hideVerticalMenuLoader();
                }
            }).catch(()=>{
                if(menuHelper.type('')==='vertical') {
                    menuHelper.hideVerticalMenuLoader();
                }
            });
        } else {
            store.commit("menu/markUpdate");
        }
    },
    indexingMenus(items, indexing=null) {
        let menus=[];
        let menu, _indexing;

        if(items.length) {
            for(let i=0; i<items.length; i++) {
                if(indexing===null) {
                    _indexing = [i];
                } else {
                    _indexing = [...indexing];
                    _indexing.push(i);
                }
                menu = Object.assign({ indexing:_indexing }, items[i]);
                menu.menus = menuHelper.indexingMenus([...items[i].menus], _indexing);
                menu.focus = false;
                menus.push(menu);
            }
        }
        return menus;
    },
    normalizeVerticalMenus(items) {
        let menus=[];
        let submenus = [];

        if(items.length) {
            [...items].forEach(item => {
                if(item.menu_type==='devider' && item.menus.length) {
                    submenus = [...item.menus];
                    item.menus=[];
                    menus.push(item);
                    submenus.forEach(submenu => {
                        menus.push(submenu);
                    });
                } else {
                    menus.push(item);
                }
            });
        }

        return menuHelper.indexingMenus(menus);
    },
    markRoute(nestedArray) {
        let route = null;
        let targetString = null;
        let menuAndParent = { menus : [...nestedArray], parent : null };

        const targetSegments = window.location.pathname.split('/').filter(Boolean);
        const sliceIndex = (appHelper.subscriberApp.listSpaApp().includes(targetSegments[0]) ? 0 : 1);
        const targetPath = targetSegments.slice(sliceIndex, targetSegments.length).join('/').split('/').filter(Boolean);
        for(let i = targetPath.length; i >=0; i--) {
            if(i>=1) {
                targetString = '/' + targetPath.slice(0, i).join('/');
            } else {
                targetString = '/' + targetPath.join('/');
            }

            route = menuHelper.findNestedMatchRoute([...nestedArray], targetString);
            if(!route) {
                route = menuHelper.findNestedSimilarRoute([...nestedArray], targetString);
            }

            if(route) {
                store.commit("menu/setPermissions", route.permission);
                store.commit("menu/setPage", route);
                menuAndParent = menuHelper.findParentRouteAndMarkActive([...nestedArray], route);
                store.commit("menu/setParent", menuAndParent.parent);
                break;
            }
        }

        return menuAndParent.menus;
    },
    findParentRouteAndMarkActive(arr, route) {
        let menu = null;
        let parent = null;

        if(route.indexing) {
            if(route.indexing.length) {
                for(let i=0; i<route.indexing.length; i++) {
                    if(menu) {
                        menu[route.indexing[i]].focus = true;
                        if(i<(route.indexing.length-1)) {
                            parent = menu[route.indexing[i]];
                        }
                        menu = menu[route.indexing[i]].menus;
                    } else {
                        arr[route.indexing[i]].focus = true;
                        if(i<(route.indexing.length-1)) {
                            parent = arr[route.indexing[i]];
                        }
                        menu = arr[route.indexing[i]].menus;
                    }
                }
            }
        }
        return { menus : arr, parent : parent };
    },
    findNestedMatchRoute(arr, targetRoute) {
        for (const obj of arr) {
            if(obj.route_frontend) {
                if(obj.route_frontend===targetRoute) {
                    if(obj.hasOwnProperty('menus')) {
                        if (!obj.menus.length) {
                            return Object.assign({}, obj);
                        }
                    } else {
                        return Object.assign({}, obj);
                    }
                }
            }

            if(obj.hasOwnProperty('menus')) {
                if (obj.menus.length > 0) {
                    const result = menuHelper.findNestedMatchRoute(obj.menus, targetRoute);
                    if (result) {
                        return Object.assign({}, result);
                    }
                }
            }
        }
        // If the object with the specified id is not found
        return null;
    },
    findNestedSimilarRoute(arr, targetRoute) {
        for (const obj of arr) {
            if(obj.route_frontend) {
                if(obj.route_frontend.match(targetRoute)) {
                    if(obj.hasOwnProperty('menus')) {
                        if (!obj.menus.length) {
                            return Object.assign({}, obj);
                        }
                    } else {
                        return Object.assign({}, obj);
                    }
                }
            }

            if(obj.hasOwnProperty('menus')) {
                if (obj.menus.length > 0) {
                    const result = menuHelper.findNestedSimilarRoute(obj.menus, targetRoute);
                    if (result) {
                        return Object.assign({}, result);
                    }
                }
            }
        }
        // If the object with the specified id is not found
        return null;
    },
    handleMenuToggleCollapsed() {
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if(windowWidth<window.Helpers.LAYOUT_BREAKPOINT && !window.Helpers.isCollapsed()) {
            window.Helpers.toggleCollapsed();
        }
    }
}
