export const formHelper = {
    reset(form) {
        if(form) {
            form.classList.remove('was-validated');
            Array.prototype.filter.call(form.querySelectorAll('.is-valid, .is-invalid'), element => {
                if(element.parentElement.querySelector('.invalid-feedback')) {
                    element.parentElement.removeChild(element.parentElement.querySelector('.invalid-feedback'));
                }
                element.classList.remove('is-valid');
                element.classList.remove('is-invalid');
            });

            Array.prototype.filter.call(form.querySelectorAll('.invalid-feedback'), invalidFeedback => {
                const parentFeedback = invalidFeedback.parentElement;
                parentFeedback.removeChild(invalidFeedback);
            });
        }
    },
    validated(form, callback) {
        this.reset(form);
        form.classList.add('was-validated');
        if (!form.checkValidity()) {
            formHelper.validatedUntouched(form);
            if(callback) {
                callback(form);
            }
            return false;
        }
        formHelper.validatedUntouched(form);
        return true;
    },
    validatedUntouched(form) {
        const quills = form.querySelectorAll('textarea.tbox-textarea-quill-editor');
        const selects = form.querySelectorAll('.tbox-select select.form-select, .tbox-date select.form-select, .tbox-number input.form-control');
        const phoneInputs = form.querySelectorAll('.tbox-phone-input');
        if(quills.length) {
            quills.forEach(quill=>{
                if(quill.previousElementSibling.classList.contains('tbox-quill-editor')) {
                    quill.previousElementSibling.classList.remove('is-valid');
                    quill.previousElementSibling.classList.remove('is-invalid');
                    quill.previousElementSibling.previousElementSibling.classList.remove('is-valid');
                    quill.previousElementSibling.previousElementSibling.classList.remove('is-invalid');
                    if(quill.checkValidity()) {
                        quill.previousElementSibling.classList.add('is-valid');
                        quill.previousElementSibling.previousElementSibling.classList.add('is-valid');
                    } else {
                        quill.previousElementSibling.classList.add('is-invalid');
                        quill.previousElementSibling.previousElementSibling.classList.add('is-invalid');
                    }
                }
            });
        }

        if(selects.length) {
            selects.forEach(select=>{
                if(select.previousElementSibling.classList.contains('form-select') || select.previousElementSibling.classList.contains('form-control')) {
                    select.previousElementSibling.classList.remove('is-valid');
                    select.previousElementSibling.classList.remove('is-invalid');
                    if(select.checkValidity()) {
                        select.previousElementSibling.classList.add('is-valid');
                    } else {
                        select.previousElementSibling.classList.add('is-invalid');
                    }
                }
            });
        }

        if(phoneInputs.length) {
            phoneInputs.forEach(phoneInput=>{
                const input = phoneInput.querySelector('input');
                const select = phoneInput.querySelector('select');

                phoneInput.classList.remove('is-valid');
                phoneInput.classList.remove('is-invalid');

                if(select) {
                    if(select.checkValidity() && input.checkValidity()) {
                        phoneInput.classList.add('is-valid');
                    } else {
                        phoneInput.classList.add('is-invalid');
                    }
                } else {
                    phoneInput.classList.add('is-invalid');
                }
            });
        }
    },
    validatePassword(password) {
        // Define the criteria
        const hasUppercase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[^\w\d]/.test(password);
        const isLengthValid = password.length >= 8;

        // Check if all criteria are met
        return hasUppercase && hasNumber && hasSpecialChar && isLengthValid;
    },
    validateEmail(email) {
        // Define a regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Test the email against the regular expression
        return emailRegex.test(email);
    },
    markInvalid(form, errors, showMessage) {
        if(typeof errors !== 'string') {
            for (const [key, error] of Object.entries(errors)) {
                let expression = '[name="'+ key +'"], [name="'+ key +'[]"]';
                if(key.lastIndexOf('.')>=0) {
                    const strArrayNested = '[' + key.replace(/\./g, '][').replace(/\[(\d+)\]/g, '[$1]') + ']';
                    expression = expression + ', [name="'+ strArrayNested.replace('[', '').replace(']', '') +'"]';
                    expression = expression + ', [name="'+ strArrayNested.replace('[', '').replace(']', '') +'[]"]';
                }

                Array.prototype.filter.call(form.querySelectorAll(expression), input => {
                    if(!input.classList.contains('is-invalid')) {
                        input.classList.add('is-invalid');

                        if(input.parentElement.classList.contains('comp-select')) {
                            input.parentElement.classList.add('is-invalid');
                        } else if(input.previousElementSibling.classList.contains('form-select') || input.previousElementSibling.classList.contains('form-control') || input.previousElementSibling.classList.contains('tbox-quill-editor')) {
                            input.previousElementSibling.classList.add('is-invalid');
                            if(input.previousElementSibling.classList.contains('tbox-quill-editor')) {
                                input.previousElementSibling.previousElementSibling.classList.add('is-invalid');
                            }
                        }

                        const phoneInput = input.closest('.tbox-phone-input');
                        if(phoneInput) {
                            phoneInput.classList.add('is-invalid');
                        }

                        if(showMessage!==undefined) {
                            const feedback = document.createElement("div");
                            const inputGroup = input.closest('.input-group');

                            feedback.classList.add('d-block');
                            feedback.classList.add('invalid-feedback');
                            feedback.classList.add('lh-1');
                            feedback.innerText = error[0];

                            if(inputGroup) {
                                if(inputGroup.parentElement.querySelector('.invalid-feedback')) {
                                    inputGroup.parentElement.removeChild(inputGroup.parentElement.querySelector('.invalid-feedback'));
                                }
                                inputGroup.parentElement.appendChild(feedback);
                            } else {
                                if(input.parentElement.classList.contains('comp-select')) {
                                    if(input.parentElement.parentElement.querySelector('.invalid-feedback')) {
                                        input.parentElement.parentElement.removeChild(input.parentElement.parentElement.querySelector('.invalid-feedback'));
                                    }
                                } else if(input.parentElement.querySelector('.invalid-feedback')) {
                                    input.parentElement.removeChild(input.parentElement.querySelector('.invalid-feedback'));
                                }

                                if(input.parentElement.classList.contains('comp-select')) {
                                    input.parentElement.parentElement.appendChild(feedback);
                                } else {
                                    input.parentElement.appendChild(feedback);
                                }
                            }
                        }
                    }
                });
            }
        }
    },
    markInvalidTab (form) {
        const element = form.querySelector('input:invalid, select:invalid, textarea:invalid, .is-invalid');
        if(element) {
            const tabPane = element.closest('.tab-pane');
            const tabContent = tabPane.closest('.tab-content');
            const tabList = tabContent.previousElementSibling;
            let tabListActive = tabList.querySelector('.nav-link.active');
            let tabPaneActive = tabContent.querySelector('.tab-pane.active');
            if(!tabPane.classList.contains('active')) {
                tabListActive.setAttribute('aria-selected', 'false');
                tabListActive.setAttribute('tabindex', '-1');
                tabListActive.classList.remove('active');
                tabPaneActive.classList.remove('show');
                tabPaneActive.classList.remove('active');
                tabListActive = tabList.querySelector('.nav-link[aria-controls="' + tabPane.id + '"]');
                tabListActive.setAttribute('aria-selected', 'true');
                tabListActive.removeAttribute('tabindex');
                tabListActive.classList.add('active');
                tabPane.classList.add('active');
                tabPane.classList.add('show');
            }
        }
    },
    keydownIsSpace(keyCode, keyValue) {
        return keyCode === 32 || keyValue === ' ';
    }
}
