/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 22/03/2024
 */

import auth from '../../../middleware/auth.js';
import shortcuts from '../../../middleware/shortcuts.js';
import {appHelper} from "../../../utils/helpers/appHelper.js";
export const TmsRegions = [
    {
        path: appHelper.path.tmsApp + '/region/currency',
        name: 'tmsAppCurrency',
        component: () => import('../../region/views/currency/index.vue')
    },
    {
        path: appHelper.path.tmsApp + '/region/country',
        name: 'tmsAppCountry',
        component: () => import('../../region/views/country/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.tmsApp + '/region/state',
        name: 'tmsAppState',
        component: () => import('../../region/views/state/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.tmsApp + '/region/city',
        name: 'tmsAppCity',
        component: () => import('../../region/views/city/index.vue'),
        meta: { showPageTitle: true, middleware: [auth, shortcuts] }
    }
];
