import {dateHelper} from "../helpers/dateHelper.js";
import {appHelper} from "../helpers/appHelper.js";

export default {
    install: (app, options) => {
        app.config.globalProperties.$gpApp = {
            userRegister : (import.meta.env.VITE_USER_REGISTER==='true'),
            userGoogle : (import.meta.env.VITE_USER_GOOGLE==='true'),
            userMicrosoft : (import.meta.env.VITE_USER_MICROSOFT==='true'),
            userLDAP : (import.meta.env.VITE_USER_LDAP==='true'),
            go : {
                urlRoot : appHelper.url.root(),
                urlApp : appHelper.url.app(),
                urlTaskApp : appHelper.url.taskApp(),
                urlAdminApp : appHelper.url.adminApp(),
                pathApp : appHelper.path.home,
            },
            date: {
                dateTime : {
                    longFormat(dateString) {
                        if(dateString) {
                            return dateHelper.dateTime.default.display.longFormat(dateString);
                        } else {
                            return '';
                        }
                    },
                    sortFormat(dateString) {
                        if(dateString) {
                            return dateHelper.dateTime.default.display.shortFormat(dateString);
                        } else {
                            return '';
                        }
                    }
                },
                dateOnly: {
                    longFormat(dateString) {
                        if(dateString) {
                            return dateHelper.date.default.display.longFormat(dateString);
                        } else {
                            return '';
                        }
                    },
                    sortFormat(dateString) {
                        if(dateString) {
                            return dateHelper.date.default.display.shortFormat(dateString);
                        } else {
                            return '';
                        }
                    }
                }
            },
            name() {
                return window.document.querySelector('html').getAttribute('data-app-name');
            },
            shortname() {
                return window.document.querySelector('html').getAttribute('data-app-shortname');
            },
            fontColor() {
                return window.document.querySelector('html').getAttribute('data-app-color');
            },
            logo() {
                return window.document.querySelector('html').getAttribute('data-logo');
            },
            background() {
                return window.document.querySelector('html').getAttribute('data-background');
            },
            getRequestUrl(stringPath){
                return appHelper.subscriberApp.getRequestUrl(stringPath);
            },
            baseSpaAppUrl(stringPath){
                return appHelper.subscriberApp.baseSpaAppUrl(stringPath);
            },
            isAppAdminSubscriber(){
                return appHelper.subscriberApp.isAppSubscriber() && appHelper.subscriberApp.isAdminApp();
            }
        }
    }
}
