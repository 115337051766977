import {TmsDefaults} from "./default.js";
import {TmsRegions} from "./region.js";

/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 23/05/2024
 */
export const TmsRouters = [
    ...TmsDefaults,
    ...TmsRegions
];
