<template>
    <div :style="backgroundStyle">
        <div class="layout-wrapper-app layout-wrapper layout-content-navbar">
            <div class="layout-container">
                <!-- Navbar -->
                <Navbar
                    ref="navbar"
                    v-if="$store.getters['auth/authUser'] && findLayout()!=='blank'"
                />
                <!-- / Navbar -->

                <!-- Layout container -->
                <div class="layout-page" :class="['lp-' + findLayoutClass()]">
                    <!-- Content wrapper -->
                    <div class="content-wrapper">
                        <!-- Menu -->
                        <NavMainMenu
                            ref="verticalMainMenu"
                            v-if="$store.getters['auth/authUser'] && findLayout()==='vertical'"
                        />
                        <NavHorizontalMenu
                            ref="horizontalMainMenu"
                            v-else-if="$store.getters['auth/authUser'] && findLayout()==='horizontal'"
                        />
                        <!-- / Menu -->

                        <!-- Content -->
                        <router-view></router-view>
                        <!--/ Content -->

                        <!-- Footer -->
                        <NavFooter
                            ref="footer"
                            v-if="$store.getters['auth/authUser'] && findLayout()!=='blank'"
                        />
                        <!-- / Footer -->

                        <div class="content-backdrop fade"></div>
                    </div>
                    <!--/ Content wrapper -->
                </div>

                <!--/ Layout container -->
            </div>
        </div>

        <!-- Overlay -->
        <div class="layout-overlay layout-menu-toggle"></div>

        <!-- Drag Target Area To SlideIn Menu On Small Screens -->
        <div class="drag-target d-none"></div>
    </div>
</template>

<script>
import NavFooter from "./layout/NavFooter.vue"
import Navbar from "./layout/Navbar.vue";
import NavMainMenu from "./layout/NavMainMenu.vue";
import {layoutHelper} from "../utils/helpers/layoutHelper.js";
import NavHorizontalMenu from "./layout/NavHorizontalMenu.vue";
import store from "../stores/store.js";
export default {
    name: "MyApp",
    components : {
        Navbar,
        NavFooter,
        NavMainMenu,
        NavHorizontalMenu
    },
    mounted() {
        this.$el.querySelector('.layout-overlay.layout-menu-toggle').addEventListener('click', event => {
            window.Helpers.toggleCollapsed();
        });
    },
    computed:{
        backgroundStyle() {
            const allowBg=['login', 'forgotPassword', 'verifyEmail', 'confirmEmail', 'twoStepVerificationEmail', 'twoStepVerification', 'resetPassword', 'register'];
            if(this.$gpApp.background() && allowBg.includes(this.$route.name)) {
                return `background-image: url(${this.$gpApp.background()});background-size: cover;background-repeat: no-repeat;background-position: center center;`;
            }
            return '';
        }
    },
    methods : {
        findLayout() {
            return layoutHelper.findLayout(this.$route.name);
        },
        findLayoutClass() {
            const layout = this.findLayout();
            return (layout==='vertical-blank' ? 'vertical' : (layout==='horizontal-blank' ? 'horizontal' : layout));
        }
    }
}
</script>

<style scoped>

</style>
