import {appHelper} from "../../utils/helpers/appHelper.js";
import auth from '../../middleware/auth.js';
import shortcuts from "../../middleware/shortcuts.js";

export const UserRouters = [
    // {
    //     path: appHelper.path.home + '/user',
    //     name: 'user',
    //     component: () => import('../../views/user/User.vue'),
    //     meta: { middleware: [auth] },
    //     children : [
    //         {
    //             path: 'profile',
    //             name: 'userProfile',
    //             component: () => import('../../views/user/UserProfile.vue'),
    //             meta: { middleware: [auth, shortcuts] }
    //         }
    //     ]
    // },
    // {
    //     path: appHelper.path.home + '/settings',
    //     name: 'userSetting',
    //     component: () => import('../../views/user/Settings.vue'),
    //     meta: { middleware: [auth] },
    //     children : [
    //         {
    //             path: 'account',
    //             name: 'settingAccount',
    //             component: () => import('../../views/user/SettingAccount.vue'),
    //             meta: { title:'Account', middleware: [auth] }
    //         },
    //         {
    //             path: 'security',
    //             name: 'settingSecurity',
    //             component: () => import('../../views/user/SettingSecurity.vue'),
    //             meta: { title:'Security', middleware: [auth] }
    //         },
    //         {
    //             path: 'company',
    //             name: 'SettingCompany',
    //             component: () => import('../../views/user/SettingCompany.vue'),
    //             meta: { title:'Company', middleware: [auth] }
    //         },
    //         {
    //             path: 'billing-address',
    //             name: 'settingBillingAddress',
    //             component: () => import('../../views/user/SettingBillingAddress.vue'),
    //             meta: { title:'Billing Address', middleware: [auth] }
    //         }
    //     ]
    // },
    {
        path: appHelper.path.home + '/user',
        name: 'myAccount',
        component: () => import('../../views/user/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
]
