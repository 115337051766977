/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 26/11/2024
 */
import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

export const AmsAppProducts = [
    {
        path: appHelper.path.amsApp + '/product/sku-product',
        name: 'amsAppSkuProduct',
        component: () => import('../views/sku-product/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        path: appHelper.path.amsApp + '/product/sku-mapping',
        name: 'amsAppSkuMapping',
        component: () => import('../views/sku-mapping/index.vue'),
        meta: { middleware: [auth, shortcuts] }
        // meta: {
        //     middleware: [auth, shortcuts],
        //     gridTitle: 'SKU Mappings',
        //     formTitle: 'SKU Mapping'
        // }
    }
];
