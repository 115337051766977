/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 23/05/2024
 */
import {appHelper} from "../../../utils/helpers/appHelper.js";
import auth from "../../../middleware/auth.js";
import shortcuts from "../../../middleware/shortcuts.js";

export const TmsDefaults = [
    {
        name: 'tmsAppCache',
        path: appHelper.path.tmsApp + '/cache',
        component: () => import('../../admin/views/cache/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'tmsAppCustomerPersonal',
        path: appHelper.path.tmsApp + '/customer/personal',
        component: () => import('../../customer/views/personal/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'tmsAppCustomerCompany',
        path: appHelper.path.tmsApp + '/customer/company',
        component: () => import('../../customer/views/company/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'tmsAppSupplier',
        path: appHelper.path.tmsApp + '/supplier',
        component: () => import('../../product/views/supplier/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    },
    {
        name: 'tmsAppProductType',
        path: appHelper.path.tmsApp + '/product-type',
        component: () => import('../../product/views/product-type/index.vue'),
        meta: { middleware: [auth, shortcuts] }
    }
];
