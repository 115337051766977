/**
 * Created by Andriyanto.
 * E-Mail Address: 4lenour@gmail.com
 * Date: 30/03/2024
 */

import Pusher from 'pusher-js';
import Echo from "laravel-echo";
import {geoKey} from "../geoKey.js";

export default {
    install: (app, options) => {
        app.config.globalProperties.$broadcast = {
            privateChannel: null,
            isEnabled() {
                return import.meta.env.VITE_REVERB_ENABLE==='true'
            },
            register() {
                if(this.isEnabled()) {
                    if(!window.Echo) {
                        window.Pusher = Pusher;
                        window.Echo = new Echo({
                            broadcaster: 'reverb',
                            key: import.meta.env.VITE_REVERB_APP_KEY,
                            wsHost: import.meta.env.VITE_REVERB_HOST,
                            wsPort: import.meta.env.VITE_REVERB_PORT,
                            wssPort: import.meta.env.VITE_REVERB_PORT,
                            forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
                            enabledTransports: ['ws', 'wss'],
                            authEndpoint: '/api/broadcasting/auth',
                            auth: {
                                headers: {
                                    Authorization: 'Bearer ' + geoKey.getTKey(),
                                    'app-gid': geoKey.getIKey()
                                }
                            }
                        });
                    } else {
                        this.connect();
                    }
                }
            },
            connect() {
                if(this.isEnabled()) {
                    if(window.Echo) {
                        window.Echo.connect();
                    }
                }
            },
            disconnect() {
                if(this.isEnabled()) {
                    if(window.Echo) {
                        window.Echo.disconnect();
                    }
                }
            },
            openPrivateChannel(userId) {
                if(this.isEnabled()) {
                    this.privateChannel = window.Echo.private('App.Models.User.' + userId)
                        .notification((notification) => {
                            if(notification.type==='broadcast.message.system') {
                                this.notificationToastr(notification);
                            }
                        });
                }
            },
            leavePrivateChannel() {
                if(this.isEnabled()) {
                    if (this.privateChannel) {
                        this.privateChannel.leave();
                        this.privateChannel=null;
                    }
                }
            },
            leaveChannel() {
                this.leavePrivateChannel();
            },
            notificationToastr(notification) {
                if((notification.message || null)) {
                    window.toastr.options = {
                        "closeButton": true,
                        "debug": false,
                        "newestOnTop": false,
                        "progressBar": false,
                        "positionClass": "toast-bottom-right",
                        "preventDuplicates": false,
                        "onclick": null,
                        "showDuration": "300",
                        "hideDuration": "1000",
                        "timeOut": "5000",
                        "extendedTimeOut": "1000",
                        "showEasing": "swing",
                        "hideEasing": "linear",
                        "showMethod": "fadeIn",
                        "hideMethod": "fadeOut"
                    }
                    if((notification.title || null)) {
                        window.toastr[(notification.kind || "info")?(notification.kind || "info"):"info"](notification.message, notification.title);
                    } else {
                        window.toastr[(notification.kind || "info")?(notification.kind || "info"):"info"](notification.message);
                    }
                    (new Audio('/templates/myapp/assets/audio/notification.wav')).play();
                }
            }
        }
    }
}
