<template>
    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <ul class="navbar-nav flex-row align-items-center ms-auto">

            <shortcuts ref="shortcuts" />
            <notification ref="notification" />

            <!-- User -->
            <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                    <div class="avatar avatar-online">
                        <img v-if="$store.getters['auth/authUser'].avatar" :src="$store.getters['auth/authUser'].avatar + '?dc=' + (new Date()).getTime() " alt="" class="h-auto rounded-circle">
                        <span v-if="!$store.getters['auth/authUser'].avatar" class="avatar-initial rounded-circle" :style="['background-color:' + avatarBgColor + ';color:' + avatarFontColor]">{{ getAlias($store.getters['auth/authUser'].alias?$store.getters['auth/authUser'].alias:$store.getters['auth/authUser'].name) }}</span>
                    </div>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                        <div class="dropdown-item">
                            <div class="d-flex">
                                <div class="flex-shrink-0 me-3">
                                    <div class="avatar" :class="[!$store.getters['auth/authUser'].loginAs?'avatar-online':'']">
                                        <img v-if="$store.getters['auth/authUser'].avatar" :src="$store.getters['auth/authUser'].avatar" alt="" class="h-auto rounded-circle">
                                        <span v-if="!$store.getters['auth/authUser'].avatar" class="avatar-initial rounded-circle" :style="['background-color:' + avatarBgColor + ';color:' + avatarFontColor]">{{ getAlias($store.getters['auth/authUser'].name) }}</span>
                                    </div>
                                </div>
                                <div class="flex-grow-1 cursor-default" :class="[$store.getters['auth/authUser'].loginAs?'lh-1':'']">
                                    <small v-if="$store.getters['auth/authUser'].loginAs" class="d-block text-danger">
                                        <a @click="logoutAs()" href="javascript:void(0)" class="text-danger">Logout As</a>
                                    </small>
                                    <span class="fw-medium d-block">{{ $store.getters['auth/authUser'].name }}</span>
                                    <small class="text-muted">{{ $store.getters['auth/authUser'].email }}</small>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li v-if="$store.getters['auth/authUser'].loginAs">
                        <div class="dropdown-item">
                            <div class="d-flex">
                                <div class="flex-shrink-0 me-3">
                                    <div class="avatar avatar-online">
                                        <img v-if="$store.getters['auth/authUser'].trueLogin.avatar" :src="$store.getters['auth/authUser'].trueLogin.avatar" alt="" class="h-auto rounded-circle">
                                        <span v-if="!$store.getters['auth/authUser'].trueLogin.avatar" class="avatar-initial rounded-circle" :style="['background-color:' + trueBgColor + ';color:' + trueFontColor]">{{ getAlias($store.getters['auth/authUser'].trueLogin.name) }}</span>
                                    </div>
                                </div>
                                <div class="flex-grow-1 lh-1 cursor-default">
                                    <small class="d-block text-primary">True Login</small>
                                    <span class="fw-medium d-block">{{ $store.getters['auth/authUser'].trueLogin.name }}</span>
                                    <small class="text-muted">{{ $store.getters['auth/authUser'].trueLogin.email }}</small>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown-divider"></div>
                    </li>
<!--                    <li>-->
<!--                        <router-link :to="{ name:'userProfile' }" class="dropdown-item">-->
<!--                            <i class="ti ti-user-check me-2 ti-sm"></i>-->
<!--                            <span class="align-middle">My Profile</span>-->
<!--                        </router-link>-->
<!--                    </li>-->
                    <li>
                        <router-link :to="{ name:'myAccount' }" class="dropdown-item">
                            <i class="ti ti-user-check me-2 ti-sm"></i>
                            <span class="align-middle">My Account</span>
                        </router-link>
                    </li>
<!--                    <li>-->
<!--                        <div class="dropdown-divider"></div>-->
<!--                    </li>-->
                    <li>
                        <a @click="logout()" class="dropdown-item" href="javascript:void(0)">
                            <i class="ti ti-logout me-2 ti-sm"></i>
                            <span class="align-middle">Log Out</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!--/ User -->
        </ul>
    </div>
</template>

<script>
import shortcuts from "./shortcuts.vue";
import notification from "./notification.vue";
import {elementHelper} from "../../utils/helpers/elementHelper.js";
import {geoKey} from "../../utils/geoKey.js";
import {appHelper} from "../../utils/helpers/appHelper.js";
import {errorHelper} from "../../utils/helpers/errorHelper.js";
import {userHelper} from "../../utils/helpers/userHelper.js";
import authClient from "../../services/auth.js";
import userClient from "../../services/user.js";
import {formHelper} from "../../utils/helpers/formHelper.js";

export default {
    name: "NavbarRight",
    components: {
        notification,
        shortcuts
    },
    data() {
        return {
            avatarBgColor : null,
            avatarFontColor : null,
            trueBgColor : null,
            trueFontColor : null
        }
    },
    created() {
        this.avatarBgColor = elementHelper.getRandomColor();
        this.avatarFontColor = elementHelper.getRandomFontColor(this.avatarBgColor);
        this.trueBgColor = elementHelper.getRandomColor();
        this.trueFontColor = elementHelper.getRandomFontColor(this.trueBgColor);
    },
    methods : {
        async logout() {
            const self = this;

            self.$broadcast.disconnect();
            await authClient.logout({gid: geoKey.getIKey()}).then(() => {
                geoKey.setTKey(null);
                self.$store.dispatch("auth/setGuest", {value: "isGuest"});
                window.location.href = appHelper.url.root() + appHelper.path.login;
            }).catch(error => {
                errorHelper.error(error);
            });

        },
        async logoutAs() {
            await userClient.logoutAs().then(result => {
                this.$broadcast.disconnect();
                window.location.href = appHelper.url.app();
            }).catch(error => {
                errorHelper.error(error);
            });
        },
        getAlias(name) {
            return userHelper.getAlias(name);
        }
    }
}
</script>

<style scoped>

</style>
